import { I18nText } from '../i18n_text';
import { NameI18nData } from './names';
import { makeDefaultApi, request } from './request';
import { TrialTraitData } from './trials';

export interface ScheduledVisitData {
  id: string | null;
  name_json: I18nText;
  code: string;
  base_date_id?: string; // read-only
  base_date_idx?: number; // write-only (wizard)
  base_date?: NameI18nData; // library
  days_offset: string | number;
  days_after_planting: string | number;
  order: number;
  n_min_visits: string | number;
  n_max_visits: string | number;
  repetition_number_of_days: string | number;
  maximum_number_of_repetitions: string | number;
  mobile_date_popup_enabled: boolean;
  traits: NameI18nData[];
  // trial-wizard only
  observations?: ScheduledVisitObservationData[];
}

export interface ScheduledVisitObservationData {
  id: string | null;
  measurement_meta_id?: string; // read-only
  measurement_meta_idx: number; // write-only
  order: number;
}

// only for library
export const { list, retrieve, save, remove } = makeDefaultApi<ScheduledVisitData>('scheduled_visits');

export interface ScheduledVisitTraitData {
  mm_id: string;
  name: I18nText;
  order: number;
  ds_id: string;
  ds_name: I18nText;
}

export interface ScheduledVisitPreviewData {
  id: string;
  name_json: I18nText;
  code: string;
  n_min_visits: number;
  n_max_visits: number;
  base_date_observation: I18nText;
  observations: ScheduledVisitTraitData[];
  is_new: boolean;
  disabled?: boolean;
  days_offset?: number;
  days_after_planting?: number;
  repetition_number_of_days: number;
  maximum_number_of_repetitions: number;
}

export function listTrialScheduledVisitsPreviews(
  trialId: string,
  traits: TrialTraitData[]
): Promise<ScheduledVisitPreviewData[]> {
  return request('POST', '/api/scheduled_visits/list_trial_scheduled_visits_preview/', {
    trial_id: trialId,
    traits,
  });
}
