import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams, getContentRange } from '../api/request';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListLoaderDelegate, ListLoader, ItemSelectionTracker } from '../components/list_loader';
import { AttributeFilters, DimensionMeta } from '../models/dimension_meta';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as stagesApi from '../api/stages';
import * as usersApi from '../api/users';
import { Stage } from '../models/stage';
import { canEditDimension } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import { Action } from '../components/basic_widgets';
import { DimensionRecordActions } from './dimension_records';
import { updateLocationWithQueryString } from '../utils';
import { FilterDelegate } from '../components/list_filters';
import { deflateSingle } from '../api/serialization';
import { removeDialog } from '../components/remove_dialog';
import { ShowArchivedFilter } from '../components/show_archived_filter';

let template = require('raw-loader!../../templates/stages.html').default;

interface DefaultFilters {
  name_search?: string;
  use_for_statistics?: string;
}

type StagesFilters = DefaultFilters & AttributeFilters;

class StagesScreen extends BaseLoadingScreen implements ListLoaderDelegate<stagesApi.StageData, Stage> {
  loader: ListLoader<stagesApi.StageData, Stage>;
  dimensionMeta = ko.observable<DimensionMeta>(null);
  showArchivedFilter: ShowArchivedFilter;
  dimensionRecordActions: DimensionRecordActions;

  canEdit = false;

  listActions = ko.observableArray<ListHeaderAction>();

  private nameFilter = ko.observable('').extend({ throttle: 300 });
  private useForStatisticsFilter = ko.observable<{ id: string; name: string }>();
  searchPlaceholder = i18n.t('Search stages')();

  private useForStatisticsChoices = [
    { id: '', name: i18n.t('All')() },
    { id: 'true', name: i18n.t('Yes')() },
    { id: 'false', name: i18n.t('No')() },
  ];
  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Use for statistics')(),
      choices: this.useForStatisticsChoices,
      value: this.useForStatisticsFilter,
    },
  ];

  allowItemsPerLoadDisplay = true;
  allowEditFilters = true;

  selectionTracker: ItemSelectionTracker<Stage>;
  selectedIds = ko.pureComputed(() => this.selectionTracker.selectedItems().map((s) => s.id()));
  bulkRemoveTooltipTitle = i18n.t('Delete selected stages')();

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: StagesFilters }) {
    super();

    this.nameFilter(params.filters.name_search || '');
    this.useForStatisticsFilter(
      params.filters.use_for_statistics
        ? this.useForStatisticsChoices.find((c) => c.id === params.filters.use_for_statistics)
        : null
    );
    this.showArchivedFilter = new ShowArchivedFilter(dimensionMetasApi.CV_STAGE_SLUG, () => {
      if (this.loader) this.loader.forceLoad();
    });

    this.dimensionRecordActions = new DimensionRecordActions(() => this.canEdit, {
      onArchive: () => this.loader.refresh(),
      onUnarchive: () => this.loader.refresh(),
    });

    let mePromise = usersApi.me();
    let dmPromise = dimensionMetasApi.retrieve(dimensionMetasApi.CV_STAGE_SLUG);
    let promise = Promise.all([mePromise, dmPromise]).then(([_, dmData]) => {
      this.canEdit = canEditDimension();
      this.dimensionMeta(new DimensionMeta(dmData));

      if (this.canEdit) {
        this.listActions.push({
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/stages/new/',
          tooltipTitle: i18n.t('Add stage')(),
        });
        this.listActions.push({
          title: i18n.t('Customize')(),
          icon: 'settings',
          href: '/dimensions/cv_stage/',
        });
        this.listActions.push({
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: '/dimensions/cv_stage/import/',
          tooltipTitle: i18n.t('Import stages')(),
        });
      }

      this.newFilters.push(...this.dimensionMeta().getAttributeFilters(params.filters));
    });
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<stagesApi.StageData, Stage>) {
    this.loader = loader;
    this.selectionTracker = new ItemSelectionTracker(this.loader.items);
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
  }

  onRefresh(stages: Stage[]): void {
    const selectedIds = new Set(this.selectedIds());
    const updatedSelected = stages.filter((s) => selectedIds.has(s.id()));
    this.selectionTracker.selectedItems(updatedSelected);
  }

  fetch(params: ListRequestParams) {
    const filters = this.getFilters();
    updateLocationWithQueryString(filters);
    return stagesApi.list({ ...params, ...filters }, undefined, {
      injectTenant: true,
      onHeadersReceived: (headers) => {
        this.selectionTracker.total(getContentRange(headers).total);
      },
    });
  }

  private getFilters() {
    return {
      name_search: this.nameFilter(),
      use_for_statistics: deflateSingle(this.useForStatisticsFilter()),
      ...this.dimensionMeta().getAttributeFilterValuesBySlug(),
      ...(this.showArchivedFilter.on() ? { archived: null } : { archived: false }),
    };
  }

  instantiate(stageData: stagesApi.StageData) {
    return new Stage(stageData, this.dimensionMeta());
  }

  remove(id: string) {
    return stagesApi.remove(id);
  }

  canRemove(stage: Stage) {
    return this.canEdit;
  }

  confirmBulkRemove = () => {
    removeDialog(
      i18n.t('Stages')().toLowerCase(),
      [],
      () =>
        stagesApi.bulkRemove({
          ...this.getFilters(),
          ...(this.selectionTracker.allSelected() ? {} : { ids: this.selectedIds() }),
          delete_limit: this.selectionTracker.selectedCount(),
        }),
      this.selectionTracker.selectedCount()
    )
      .then(() => {
        this.loader.refresh();
        this.selectionTracker.clear();
      })
      .catch(() => {});
  };

  getActions(stage: Stage): Action[] {
    return this.dimensionRecordActions.getAll({
      id: stage.id(),
      dimensionMetaNameSlug: this.dimensionMeta().nameSlug(),
      archived: stage.archived(),
    });
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let stages = {
  name: 'stages',
  viewModel: StagesScreen,
  template: template,
};

ko.components.register(stages.name, stages);
