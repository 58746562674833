import * as ko from 'knockout';
import i18n from '../i18n';

import { Trial, TrialWizard } from './trial';
import { DatasetDimensionMetaData, LimitToDimensionData, MMLimitToDimensionData } from '../api/datasets';
import * as dimensionsApi from '../api/dimensions';
import * as dimensionMetasApi from '../api/dimension_metas';
import { Dimension } from '../models/dimension';
import { DimensionMeta } from '../models/dimension_meta';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { DimensionData } from '../api/dimensions';
import { I18nText } from '../i18n_text';
import { RegionData } from '../api/regions';
import { SiteData } from '../api/sites';
import { asArray } from '../utils';

export class LimitToDimension extends Dimension {
  private oldControl = false;

  control = ko.observable(false);
  disabled = ko.observable(false);
  optional = ko.observable(false);
  nameOverride = ko.observable<string>('');
  region = ko.observable<RegionData>(null);
  includeInFactorialCombinations = ko.observable(true);

  // Override to make not required because "restricted manager" users won't have access to this field.
  nameJson = ko.observable<I18nText>().extend({
    serverError: true,
  });

  private subscription: KnockoutSubscription;

  constructor(
    trialWizard: TrialWizard | null,
    dimensionMeta: DimensionMeta,
    data: LimitToDimensionData | DimensionData | SiteData
  ) {
    super(data, dimensionMeta);

    if (data) {
      this.nameJson(data.name_json);
    }
    if (data && (data as LimitToDimensionData).control) {
      this.control((data as LimitToDimensionData).control);
      this.oldControl = this.control();
    }
    if (data && (data as LimitToDimensionData).disabled) {
      this.disabled((data as LimitToDimensionData).disabled);
    }
    if (data && (data as LimitToDimensionData).optional) {
      this.optional((data as LimitToDimensionData).optional);
    }
    if (data && (data as LimitToDimensionData).name_override) {
      this.nameOverride((data as LimitToDimensionData).name_override);
    }
    if (data && (data as LimitToDimensionData).include_in_treatment_factorial_combinations != undefined) {
      this.includeInFactorialCombinations((data as LimitToDimensionData).include_in_treatment_factorial_combinations);
    }
    if (data && (data as SiteData).region) {
      this.region((data as SiteData).region);
    }


    if (trialWizard) {
      this.subscription = this.control.subscribe(() => this.onControlChanged(trialWizard));
    }
  }

  dispose() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  toData(): LimitToDimensionData {
    return {
      control: this.control(),
      disabled: this.disabled(),
      optional: this.optional(),
      name_override: this.nameOverride(),
      include_in_treatment_factorial_combinations: this.includeInFactorialCombinations(),
      ...super.toData(),
    };
  }

  toMMData(order: number): MMLimitToDimensionData {
    return {
      order,
      ...super.toData(),
    };
  }

  private onControlChanged(wizard: TrialWizard) {
    let newControl = this.control();

    if (!wizard || wizard.trial().plotDesign() !== 'customer' || newControl === this.oldControl) {
      return;
    }

    wizard
      .confirmChangeAffectingPlots(i18n.t(['control_lowercase', 'control'])())
      .then(() => {
        this.oldControl = newControl;
      })
      .catch(() => {
        this.control(this.oldControl);
      });
  }
}

export enum AnonymizeOptions {
  VISIBLE = 'visible',
  ANONYMIZE_PARTIAL = 'anonymize_partial',
  ANONYMIZE = 'anonymize',
}

export class DatasetDimensionMeta {
  static predefinedSlugs = [
    dimensionMetasApi.CROP_VARIETY_SLUG,
    dimensionMetasApi.FERTILIZER_SLUG,
    dimensionMetasApi.CHEMICAL_SLUG,
    dimensionMetasApi.PROTOCOL_SLUG,
  ];

  anonymizeOptions: { name: string; value: string }[] = [
    { name: i18n.t('Show name')(), value: AnonymizeOptions.VISIBLE },
    { name: i18n.t('Use anonymized code')(), value: AnonymizeOptions.ANONYMIZE_PARTIAL },
    { name: i18n.t('Fully anonymize')(), value: AnonymizeOptions.ANONYMIZE },
  ];

  id = ko.observable<string>(null);
  dimensionMeta = ko.observable<DimensionMeta>(null).extend({
    required: true,
    serverError: true,
  });
  order = ko.observable<number>(null);
  limitTo = ko.observableArray<LimitToDimension>().extend({
    required: true,
  });
  allowCreate = ko.observable(false);

  anonymize = ko.observable(APP_CONFIG.DEFAULT_ANONYMIZATION);
  useForPlot = ko.observable(true);
  required = ko.observable(true);
  limitValues = ko.observable(false);

  // is this object representing one of the predefined dimension metas?
  isPredefined = ko.observable(false);

  nameJson = ko.pureComputed<string | I18nText>(() => {
    if (this.dimensionMeta()) {
      return this.dimensionMeta().nameJson();
    } else {
      return i18n.t('Not selected')();
    }
  });

  isNew = ko.pureComputed(() => {
    return !this.id();
  });

  private subscription: KnockoutSubscription;
  private errors = ko.validation.group(this);

  hasErrors() {
    return this.errors().length > 0 || !!this.dimensionMeta.serverError();
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  dimensionMetaSearchConfig: FormSelectSearchConfiguration<DimensionMeta> = {
    getSummaryName: (dimensionMeta) => {
      return dimensionMeta.nameJson();
    },

    list: (params) => {
      return dimensionMetasApi
        .listExcludingDates(params)
        .then((result) => result.map((data) => new DimensionMeta(data)));
    },

    entity: this.dimensionMeta,

    create: {
      title: i18n.t('Dimension')(),
      componentName: 'dimension-meta-edit',
      instantiate: (data) => new DimensionMeta(<dimensionMetasApi.DimensionMetaData>data),
      extraParams: { disableDate: true },
    },
  };

  // required by legacy dataset editing
  dimensionSearchConfig: FormSelectSearchConfiguration<LimitToDimension> = {
    title: i18n.t('Included dimension records:')(),
    getSummaryName: (dimension) => {
      return dimension.nameJson();
    },

    list: (params) => {
      if (!this.dimensionMeta()) {
        return Promise.resolve([]);
      }

      return dimensionsApi
        .list(this.dimensionMeta().id(), { crop_ids: asArray(this.trial.crop().id) }, params)
        .then((res) => {
          return res.map((data) => new Dimension(data));
        });
    },

    entities: this.limitTo,
  };

  trial: Trial;
  trialWizard: TrialWizard;

  constructor(trialWizard: TrialWizard, data?: DatasetDimensionMetaData, isPredefined?: boolean) {
    this.trialWizard = trialWizard;
    this.trial = trialWizard.trial();

    if (data) {
      this.id(data.id);
      this.dimensionMeta(new DimensionMeta(data.dimension_meta_id));
      this.order(data.order);
      if (data.limit_to) {
        this.limitTo(
          data.limit_to.map((data) => new LimitToDimension(trialWizard, this.dimensionMeta(), data))
        );
      }
      this.allowCreate(data.allow_create);
      this.limitValues(this.limitTo().length > 0);
      this.anonymize(data.visibility);
      this.useForPlot(data.use_for_plot);
      this.required(data.required);
    }

    if (isPredefined !== undefined) {
      this.isPredefined(isPredefined);
    }

    this.subscription = this.dimensionMeta.subscribe(() => {
      this.dimensionMeta.serverError(null);
      this.limitTo.removeAll();
      trialWizard.onTestSubjectsUpdated();
    });

    // regenarate plot when control changes and excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects is true
    // since it can impact a plot layout
    function onControlChange(trialWizard: TrialWizard) {
      if (trialWizard.excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects()) {
        trialWizard.forceRegeneratePlots("full");
      }
    }
    ko.computed(() => {
      var self = this;
      this.limitTo().forEach(function (item) {
        item.control.subscribe(() => onControlChange(self.trialWizard));
      });
    });
  }

  dispose() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  toData(forceLimitTo: boolean): DatasetDimensionMetaData {
    return {
      id: this.id(),
      dimension_meta_id: this.dimensionMeta() ? this.dimensionMeta().toData() : null,
      order: this.order(),
      limit_to:
        forceLimitTo || this.limitValues() ? this.limitTo().map((dimension) => dimension.toData()) : [],
      visibility: this.anonymize(),
      use_for_plot: this.useForPlot(),
      required: this.required(),
      allow_create: this.allowCreate(),
    };
  }
}
