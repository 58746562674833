import * as ko from 'knockout';

import { DownloadableReportLayoutData, getDownloadableReportLayoutUploadEndpoint } from '../api/downloadable_report';
import { FileCloudStorageUploadDelegate } from '../file_upload';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { parseDateTime } from '../api/serialization';

export class DownloadableReport {
  id = ko.observable<number>(null);
  name = ko.observable<string>(null).extend({
    serverError: true
  });
  description = ko.observable<string>(null);
  userFileName = ko.observable<string>(null);
  fileName = ko.observable<string>(null);
  modified = ko.observable<string>(null);
  modifiedByUserName = ko.observable<string>(null);
  subscriptions: KnockoutSubscription[] = [];

  editUrl() {
    return `/global_report_templates/${this.id()}/`;
  }

  globalReportTemplateUpload = new FileCloudStorageUploadDelegate({
    getUploadEndpoint: (contentType: string): Promise<FileUploadEndpoint> => {
      return getDownloadableReportLayoutUploadEndpoint(contentType);
    },
  });

  constructor(data?: DownloadableReportLayoutData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.description(data.description);
      this.userFileName(data.user_file_name);
      this.modified(parseDateTime(data.modified).toLocaleString());
      this.modifiedByUserName(data.modified_by_user_name);
      this.globalReportTemplateUpload.fileName(data.file_name);
    }
    this.globalReportTemplateUpload.userFileName.subscribe((fileName) => {
      if (fileName) {
        this.userFileName(fileName);
      }
    });
  }

  toData(): DownloadableReportLayoutData {
    return {
      id: this.id(),
      name: this.name(),
      description: this.description(),
      user_file_name: this.userFileName(),
      file_name: this.globalReportTemplateUpload.fileName(),
    };
  }

  dispose() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }
}
