import {
  GetOptions,
  ListRequestParams,
  RemoveResult,
  RequestOptions,
  listParamsToQueryString,
  makeDefaultApi,
  requestBulkRemoveEntities,
} from './request';
import { DimensionData } from './dimensions';

export interface StageData extends DimensionData {
  count_for_stats: boolean;
  show_in_s2bim: boolean;
}

interface StageListRequestParams {
  country_ids?: string[];
  archived?: boolean;
}

const stagesApi = makeDefaultApi<StageData, StageListRequestParams>('stages');

export const { retrieve, save, remove } = stagesApi;

/** Wrapper that adds default parameters to the list request */
export function list(
  params: ListRequestParams & StageListRequestParams & { type?: string },
  options?: GetOptions,
  requestOptions?: RequestOptions
): Promise<StageData[]> {
  const defaultParams: StageListRequestParams = {
    archived: false,
  };
  return stagesApi.list({ ...defaultParams, ...params }, options, requestOptions);
}

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/stages/bulk_remove/${queryParameters}`);
}
