import {
  GetOptions,
  ListRequestParams,
  RemoveResult,
  RequestOptions,
  listParamsToQueryString,
  makeDefaultApi,
  requestBulkRemoveEntities,
} from './request';
import { DimensionData } from './dimensions';
import { NameI18nData } from './names';

export interface CropData extends DimensionData {
  category?: NameI18nData;
  traits?: NameI18nData[];
}

interface CropListRequestParams {
  ids?: string[];
  withAdditionalData?: boolean;
  archived?: boolean;
}

const cropsApi = makeDefaultApi<CropData, CropListRequestParams>('crops');

export const { retrieve, save, remove } = cropsApi;

/** Wrapper that adds default parameters to the list request */
export function list(
  params: ListRequestParams &
    CropListRequestParams & {
      type?: string;
    },
  options?: GetOptions,
  requestOptions?: RequestOptions
): Promise<CropData[]> {
  const defaultParams: CropListRequestParams = {
    archived: false,
  };
  return cropsApi.list({ ...defaultParams, ...params }, options, requestOptions);
}

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/crops/bulk_remove/${queryParameters}`);
}
