/**
 * Filter for showing archived items in a list. Saves the state in session storage
 * so that it persists across page reloads.
 *
 * @param {string} pageId - A unique identifier for the page.
 * @param {() => void} onFilterChanged - A callback that is called when the filter changes.
 **/
export class ShowArchivedFilter {
  /** Whether the filter is on or off */
  on = ko.observable<boolean>(false);

  private pageId: string;
  private onFilterChanged: () => void;

  subscriptions: KnockoutSubscription[] = [];

  constructor(pageId: string, onFilterChanged: () => void) {
    this.pageId = pageId;
    this.onFilterChanged = onFilterChanged;

    this.on(this.getInitialFilterValue());

    this.subscriptions.push(this.on.subscribe(this.handleChange, this));
  }

  /*
   * The first time the page is loaded there is no query parameter in the URL, but we
   * still want to filter out archived elements. This is why sessionStorage is used.
   */
  private getInitialFilterValue(): boolean {
    const id = this.pageId;
    if (!id) return false;
    return sessionStorage.getItem(`showArchived-${id}`) === 'true';
  }

  private handleChange() {
    const id = this.pageId;
    if (!id) return;
    sessionStorage.setItem(`showArchived-${id}`, String(this.on()));

    this.onFilterChanged();
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
  }
}
