import * as ko from 'knockout';

import { ListRequestParams, getContentRange } from '../api/request';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListLoaderDelegate, ListLoader, ItemSelectionTracker } from '../components/list_loader';
import { AttributeFilters, DimensionMeta } from '../models/dimension_meta';
import { Crop } from '../models/crop';
import * as cropsApi from '../api/crops';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as namesApi from '../api/names';
import i18n from '../i18n';
import { ListHeaderAction } from '../components/list_header';
import { Action } from '../components/basic_widgets';
import { DimensionRecordActions } from './dimension_records';
import { canEditCrops } from '../permissions';
import { session } from '../session';
import { asArray, updateLocationWithQueryString } from '../utils';
import { FilterDelegate } from '../components/list_filters';
import { deflateList } from '../api/serialization';
import { removeDialog } from '../components/remove_dialog';
import { ShowArchivedFilter } from '../components/show_archived_filter';

let template = require('raw-loader!../../templates/crops.html').default;

interface DefaultFilters {
  name_search?: string;
  anonymized_code?: string;
  crop_category_ids?: string | string[];
}

type CropsFilters = DefaultFilters & AttributeFilters;

class CropsScreen extends BaseLoadingScreen implements ListLoaderDelegate<cropsApi.CropData, Crop> {
  loader: ListLoader<cropsApi.CropData, Crop>;
  showArchivedFilter: ShowArchivedFilter;
  dimensionRecordActions: DimensionRecordActions;

  canEdit = canEditCrops();
  canViewDetails = session.isFieldtrials();

  dimensionMeta = ko.observable<DimensionMeta>(null);

  listActions = ko.observableArray<ListHeaderAction>([]);

  private nameFilter = ko.observable('').extend({ throttle: 300 });
  private anonymizedCodeFilter = ko.observable('').extend({ throttle: 300 });
  private categoryFilter = ko.observableArray<namesApi.NameI18nData>();
  searchPlaceholder = i18n.t('Search crops')();

  private listCropCategories = (params: ListRequestParams) => namesApi.list('crop_categories', params);

  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Anonymized code')(),
      textValue: this.anonymizedCodeFilter,
    },
    {
      title: i18n.t('Category')(),
      entities: this.categoryFilter,
      list: this.listCropCategories,
    },
  ];

  allowItemsPerLoadDisplay = true;
  allowEditFilters = true;

  selectionTracker: ItemSelectionTracker<Crop>;
  selectedIds = ko.pureComputed(() => this.selectionTracker.selectedItems().map((c) => c.id()));
  bulkRemoveTooltipTitle = i18n.t('Delete selected crops')();

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: CropsFilters }) {
    super();

    this.nameFilter(params.filters.name_search || '');
    this.anonymizedCodeFilter(params.filters.anonymized_code || '');

    this.showArchivedFilter = new ShowArchivedFilter(dimensionMetasApi.CROP_SLUG, () => {
      if (this.loader) this.loader.forceLoad();
    });

    this.dimensionRecordActions = new DimensionRecordActions(() => this.canEdit, {
      onArchive: () => this.loader.refresh(),
      onUnarchive: () => this.loader.refresh(),
    });

    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/crops/new/',
        tooltipTitle: i18n.t('Add crop')(),
      });
      this.listActions.push({
        title: i18n.t('Customize')(),
        icon: 'settings',
        href: '/dimensions/crop/',
      });
      this.listActions.push({
        title: i18n.t('Import')(),
        icon: 'file_upload',
        href: '/dimensions/crop/import/',
        tooltipTitle: i18n.t('Import crops')(),
      });
    }

    let dmPromise = dimensionMetasApi.retrieve(dimensionMetasApi.CROP_SLUG);
    let promise = Promise.all([this.listCropCategories({}), dmPromise]).then(
      ([cropCategoriesData, dmData]) => {
        this.dimensionMeta(new DimensionMeta(dmData));
        this.newFilters.push(...this.dimensionMeta().getAttributeFilters(params.filters));

        if (params.filters.crop_category_ids) {
          this.categoryFilter(
            cropCategoriesData.filter((cat) => asArray(params.filters.crop_category_ids).includes(cat.id))
          );
        }
      }
    );
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<cropsApi.CropData, Crop>) {
    this.loader = loader;
    this.selectionTracker = new ItemSelectionTracker(this.loader.items);
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
  }

  onRefresh(crops: Crop[]): void {
    const selectedIds = new Set(this.selectedIds());
    const updatedSelected = crops.filter((c) => selectedIds.has(c.id()));
    this.selectionTracker.selectedItems(updatedSelected);
  }

  fetch(params: ListRequestParams) {
    const filters = this.getFilters();
    updateLocationWithQueryString(filters);
    return cropsApi.list({ ...params, ...filters, withAdditionalData: true }, undefined, {
      injectTenant: true,
      onHeadersReceived: (headers) => {
        this.selectionTracker.total(getContentRange(headers).total);
      },
    });
  }

  private getFilters() {
    return {
      name_search: this.nameFilter(),
      anonymized_code: this.anonymizedCodeFilter(),
      crop_category_ids: deflateList(this.categoryFilter),
      ...this.dimensionMeta().getAttributeFilterValuesBySlug(),
      ...(this.showArchivedFilter.on() ? { archived: null } : { archived: false }),
    };
  }

  instantiate(data: cropsApi.CropData) {
    return new Crop(data, this.dimensionMeta());
  }

  getEditUrl(entity: Crop) {
    return this.canEdit && entity.editUrl();
  }

  remove(id: string) {
    return cropsApi.remove(id);
  }

  canRemove(crop: Crop) {
    return this.canEdit;
  }

  confirmBulkRemove = () => {
    removeDialog(
      i18n.t('Crops')().toLowerCase(),
      [],
      () =>
        cropsApi.bulkRemove({
          ...this.getFilters(),
          ...(this.selectionTracker.allSelected() ? {} : { ids: this.selectedIds() }),
          delete_limit: this.selectionTracker.selectedCount(),
        }),
      this.selectionTracker.selectedCount()
    )
      .then(() => {
        this.loader.refresh();
        this.selectionTracker.clear();
      })
      .catch(() => {});
  };

  getActions(crop: Crop): Action[] {
    return this.dimensionRecordActions.getAll({
      id: crop.id(),
      dimensionMetaNameSlug: this.dimensionMeta().nameSlug(),
      archived: crop.archived(),
    });
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let crops = {
  name: 'crops',
  viewModel: CropsScreen,
  template: template,
};

ko.components.register(crops.name, crops);
