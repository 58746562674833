import {
  GetOptions,
  listRequest,
  ListRequestParams,
  makeDefaultApi,
  request,
  RequestOptions,
} from './request';
import { DimensionData, AdvancedSearchListRequestParams } from './dimensions';
import { RegionData } from './regions';
import { CountryData } from './countries';
import { AgroRegionData } from './agro_regions';
import { NameData } from './names';
import { GeoJSON } from './datasets';
import { Point } from '../ko_bindings/map';

export interface SiteData extends DimensionData {
  country: CountryData;
  region: RegionData;
  agro_region: AgroRegionData;
  partner: NameData;
  customer: NameData;
  site_area: GeoJSON;
  gps_location: Point;
  geographical_zones?: {
    [key in SiteGeoZoneType]: GeoZone;
  };
}

export interface GeoZone {
  grid_code: number;
  id: number;
}

export interface GeoZoneColored extends GeoZone {
  color: string;
}

export enum SiteGeoZoneType {
  GYGA_CLIMATE_ZONE = 'GYGA_CLIMATE_ZONE',
  GYGA_TED_ZONE = 'GYGA_TED_ZONE',
}

interface SiteListRequestParams {
  trial_id?: string;
  dataset_id?: string;
  country_ids?: string[];
  ids?: string[];
  disabled?: boolean;
  include_geo_zones?: boolean;
  archived?: boolean;
}

const sitesApi = makeDefaultApi<SiteData, SiteListRequestParams>('sites');

export const { retrieve, save, remove } = sitesApi;

/** Wrapper that adds default parameters to the list request */
export function list(
  params: ListRequestParams & SiteListRequestParams & { type?: string },
  options?: GetOptions,
  requestOptions?: RequestOptions
): Promise<SiteData[]> {
  const defaultParams: SiteListRequestParams = {
    archived: false,
  };
  return sitesApi.list({ ...defaultParams, ...params }, options, requestOptions);
}

export function advancedSearch(params: AdvancedSearchListRequestParams): Promise<DimensionData[]> {
  return listRequest('/api/sites/advanced_search/', params);
}

// uses POST to handle a large number of ids
export function listIds(ids: string[]): Promise<SiteData[]> {
  return request('POST', '/api/sites/list_ids/', { ids });
}
