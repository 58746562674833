import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { MeasurementType } from '../models/measurement_type';
import * as measurementTypesApi from '../api/measurement_types';
import * as cropsApi from '../api/crops';
import * as dimensionsApi from '../api/dimensions';
import * as usersApi from '../api/users';
import { BaseLoadingScreen } from './base_loading_screen';
import { downloadBlob, updateLocationWithQueryString, asArray } from '../utils';
import { deflateList } from '../api/serialization';
import { canEditMeasurementType } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import { FilterDelegate } from '../components/list_filters';
import { session } from '../session';

let template = require('raw-loader!../../templates/measurement_types.html').default;

class MeasurementTypesScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<measurementTypesApi.MeasurementTypeData, MeasurementType>
{
  exporting = ko.observable(false);
  canEdit = canEditMeasurementType(session.tenant());

  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>(null);
  newFilters: FilterDelegate[] = [
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
  ];
  listActions: ListHeaderAction[];

  constructor(params: { filters: { crop_ids: string | string[] } }) {
    super();
    this.listActions = [];
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/measurement_types/new/',
        tooltipTitle: i18n.t('Add measurement type')(),
      });
    }
    this.listActions.push({
      title: i18n.t('Export')(),
      icon: 'file_download',
      onClick: this.exportAll,
      loading: this.exporting,
    });

    let cropIds = asArray(params.filters.crop_ids);
    if (cropIds.length > 0) {
      let cropPromise = cropsApi.list({ ids: cropIds });
      let userPromise = usersApi.me();
      let promise = Promise.all([userPromise, cropPromise]).then(([userData, cropData]) => {
        this.cropFilter(cropData);
      });
      this.loadedAfter(promise);
    } else {
      this.loaded();
    }
  }

  fetch(params: ListRequestParams) {
    let filter = { crop_ids: deflateList(this.cropFilter) };
    updateLocationWithQueryString(filter);

    return measurementTypesApi.list({ ...filter, ...params });
  }

  instantiate(userData: measurementTypesApi.MeasurementTypeData) {
    return new MeasurementType(userData);
  }

  remove(id: string) {
    return measurementTypesApi.remove(id);
  }

  canRemove(measurementType: MeasurementType) {
    return this.canEdit;
  }

  exportAll = () => {
    this.exporting(true);

    measurementTypesApi
      .exportAll()
      .then((data) => {
        this.exporting(false);
        downloadBlob(data, 'measurement_types.xlsx');
      })
      .catch(() => {
        this.exporting(false);
      });
  };
}

export let measurementTypes = {
  name: 'measurement-types',
  viewModel: MeasurementTypesScreen,
  template: template,
};

ko.components.register(measurementTypes.name, measurementTypes);
