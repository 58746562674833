import { FileUploadDelegate } from './components/basic_widgets';
import { CloudStorageUpload, CloudStorageUploadDelegate, FileUploadEndpoint } from './cloud_storage_upload';
import { getFactUploadEndpoint } from './data_entry/data_entry_api';
import { getFileExtension, getFileNameWithoutExtension } from './utils';
import { uuid4 } from './utils/uuid';

export interface FileUploadConfig {
  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
}
export class FileCloudStorageUploadDelegate
  implements FileUploadDelegate, CloudStorageUploadDelegate<{ file_name: string }>
{
  canReuseEndpoint = false;

  contentType: string = null;
  url: string = null;
  fileName = ko.observable<string>(null);
  userFileName = ko.observable<string>(null);

  getUploadEndpoint = (
    contentType: string,
    params?: {
      file_name: string;
    }
  ): Promise<FileUploadEndpoint> => {
    return getFactUploadEndpoint(contentType, params);
  };
  constructor(fileUploadConfig?: FileUploadConfig) {
    if (fileUploadConfig) {
      this.getUploadEndpoint = fileUploadConfig.getUploadEndpoint
    }
  }
  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;
  uploading = this.cloudUpload.uploading;

  /**
   * Files are uploaded with naming convention `originalFileName_filenameTimestamp_UUID.extension”
   * where filenameTimeStamp = "yyyyMMdd_HHmmss", and UUID = UUID with no “-” between
   * sections in it.
   *
   * e.g. myFile.pdf -> "myFile_20210930_123456_1234567890abcdef.pdf"
   */
  generateUploadFileName = (userFileName: string) => {
    const fileNamePrefix =
      getFileNameWithoutExtension(userFileName) +
      '_' +
      new Date()
        .toISOString()
        .replace(/[^0-9T]/g, '')
        .replace('T', '_')
        .slice(0, -3) +
      '_';
    return fileNamePrefix + uuid4() + getFileExtension(userFileName);
  };

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    if (!contentType) {
      contentType = 'application/octet-stream';
    }
    this.cloudUpload.setUploadEndpointParams({ file_name: this.generateUploadFileName(userFileName) });
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  };

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    this.contentType = contentType;
    this.url = publicURL;
    this.fileName(fileName);
    this.userFileName(userFileName);
  }

  getUserFileNameFromUploadFileName() {
    const uploadFileName = this.getFileName();
    const parts = uploadFileName.split('_');
    if (parts.length < 4) {
      // Not enough parts, return the filename as it is
      return uploadFileName;
    }
    const originalFileNameParts = parts.slice(0, -3); // Exclude the timestamp and UUID
    return originalFileNameParts.join('_') + getFileExtension(uploadFileName);
  }

  getUserFileName() {
    if (!this.userFileName()) {
      this.userFileName(this.getUserFileNameFromUploadFileName());
    }
    return this.userFileName();
  }

  getFileName() {
    return this.fileName() ?? '';
  }

  setFileName(fileName: string) {
    this.fileName(fileName);
  }

  setUrl(url: string) {
    this.url = url;
  }

  getRawValue() {
    return { url: this.url, name: this.getFileName() };
  }

  setRawValue(value?: { url: string; name: string }) {
    this.url = value?.url ?? '';
    this.fileName(value?.name ?? '');
    this.userFileName(this.getUserFileNameFromUploadFileName());
  }
}
