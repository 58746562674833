import * as ko from 'knockout';

import { DimensionData, ScheduledApplicationData } from '../api/dimensions';
import { DimensionMeta } from './dimension_meta';
import { DynamicAttribute, makeDynamicAttribute } from './attribute_meta';
import { I18nText, translate } from '../i18n_text';
import { FormSelectCreate } from '../components/form_select_search';
import {
  CROP_SLUG,
  CROP_VARIETY_SLUG,
  CV_STAGE_SLUG,
  DimensionMetaData,
  SITE_SLUG,
  TRIAL_TYPE_SLUG,
} from '../api/dimension_metas';
import { KOMaybeArray } from '../utils/ko_utils';
import { CountryData } from '../api/countries';
import { RegionData } from '../api/regions';
import { UnitData } from '../api/units';
import {
  getDMSearchConfig,
  getDimensionSearchConfig,
  getMMLibrarySearchConfig,
  getUnitSearchConfig,
} from '../components/configs/search_configs';
import { emptyToNull, INTEGER_VALIDATION_RULES, readDecimal } from '../utils';
import { NameI18nData } from '../api/names';
import { CropData } from '../api/crops';
import { SlugGenerator } from '../ko_bindings/slug_validation';

export class Dimension {
  private slugGenerator: SlugGenerator;

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  code = ko.observable('').extend({ serverError: true, required: true });
  anonymizedCode = ko.observable('');
  dimensionMetaId = ko.observable('');
  attributes = ko.observableArray<DynamicAttribute>();
  crops = ko.observableArray<CropData>([]);
  archived = ko.observable<boolean>(false);
  crop_names = ko.pureComputed(() =>
    this.crops()
      .map((crop) => translate(crop.name_json))
      .join(', ')
  );

  editUrl = ko.pureComputed(() => {
    return '/dimensions/' + this.dimensionMetaId() + '/elements/' + this.id() + '/';
  });

  constructor(data?: DimensionData, dimensionMeta?: DimensionMeta) {
    this.initFromData(data, dimensionMeta);
  }

  initFromData(data?: DimensionData, dimensionMeta?: DimensionMeta) {
    if (dimensionMeta) {
      this.dimensionMetaId(dimensionMeta.id());
      this.attributes(
        dimensionMeta.attributeMetas().map((attributeMeta) => makeDynamicAttribute(attributeMeta, {}))
      );
    }

    if (data) {
      let attributesData = <{ [attr: string]: {} }>(<any>data);

      this.id(data.id);
      this.code(data.code);
      this.anonymizedCode(data.anonymized_code);
      this.dimensionMetaId(data.dimension_meta_id);
      this.nameJson(data.name_json);
      this.crops(data.crops);

      for (let attribute of this.attributes()) {
        attribute.deserialize(attributesData[attribute.getAttrName()]);
      }

      this.archived(data.archived);
    }

    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.code, {
      canEdit: !this.id(),
      fillIfEmpty: true,
    });
  }

  toData(): DimensionData {
    let data: DimensionData = {
      id: this.id(),
      name_json: this.nameJson(),
      code: this.code(),
      anonymized_code: this.anonymizedCode(),
      dimension_meta_id: this.dimensionMetaId(),
      crops: this.crops(),
    };

    let attributesData = <{ [attr: string]: {} }>(<any>data);

    for (let attribute of this.attributes()) {
      attributesData[attribute.getAttrName()] = attribute.serialize();
    }

    return <DimensionData>(<any>attributesData);
  }

  dispose() {
    this.slugGenerator.dispose();
  }
}

export class ScheduledApplication {
  id: string;
  inputDimensionDM = ko.observable<DimensionMetaData>().extend({ required: true });
  inputDimension = ko.observable<DimensionData>().extend({ required: true });
  baseDate = ko.observable<NameI18nData>(null);
  daysOffset = ko.observable('').extend(INTEGER_VALIDATION_RULES);
  estimatedDaysAfterPlanting = ko.observable('').extend(INTEGER_VALIDATION_RULES);
  dosage = ko.observable('').extend({ serverError: true, number: true });
  unit = ko.observable<UnitData>(null);

  inputDimensionDMSearch = getDMSearchConfig(this.inputDimensionDM);
  inputDimensionSearch = ko.pureComputed(() =>
    getDimensionSearchConfig(this.inputDimension, this.inputDimensionDM())
  );
  baseDateSearch = getMMLibrarySearchConfig(this.baseDate, null, null, null, {
    onlyFor: 'date',
    management: null,
  });
  unitSearch = getUnitSearchConfig(this.unit);

  subscriptions: KnockoutSubscription[] = [];

  errors = ko.validation.group(this);

  constructor(data?: ScheduledApplicationData) {
    if (data) {
      this.id = data.id;
      this.inputDimensionDM(data.input_dimension_dm);
      this.inputDimension(data.input_dimension);
      this.baseDate(data.base_date);
      this.daysOffset(data.days_offset?.toString() ?? '');
      this.estimatedDaysAfterPlanting(data.estimated_days_after_planting?.toString() ?? '');
      this.dosage(readDecimal(data.dosage));
      this.unit(data.unit);
    }

    this.subscriptions.push(this.inputDimensionDM.subscribe(this.onDMChanged));
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }

  toData(): ScheduledApplicationData {
    const estimatedDaysAfterPlanting = parseInt(this.estimatedDaysAfterPlanting(), 10);

    return {
      id: this.id,
      input_dimension: this.inputDimension(),
      base_date: this.baseDate(),
      days_offset: emptyToNull(this.daysOffset()),
      estimated_days_after_planting: isNaN(estimatedDaysAfterPlanting) ? null : estimatedDaysAfterPlanting,
      dosage: emptyToNull(this.dosage()),
      unit: this.unit(),
    };
  }

  hasErrors(): boolean {
    return this.errors().length > 0 || !!this.dosage.serverError();
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  private onDMChanged = () => {
    if (this.inputDimension() && this.inputDimension().dimension_meta_id !== this.inputDimensionDM()?.id) {
      this.inputDimension(null);
    }
  };
}

export function getDimensionCreate<T>(
  dm: DimensionMetaData,
  options?: {
    initialCrop?: KOMaybeArray<DimensionData>;
    initialCountry?: ko.Observable<CountryData>;
    initialRegion?: ko.Observable<RegionData>;
  }
): FormSelectCreate<DimensionData, T> {
  const dmSlug = dm.slug;

  let componentName = 'dimension-record-edit';
  let extraParams: { [key: string]: {} } = { dimensionMetaId: dm.id, initialCrop: options?.initialCrop };
  if (dmSlug === CROP_VARIETY_SLUG) {
    componentName = 'crop-variety-edit';
  } else if (dmSlug === SITE_SLUG) {
    componentName = 'site-edit';
    extraParams['initialCountry'] = options?.initialCountry;
    extraParams['initialRegion'] = options?.initialRegion;
  } else if (dmSlug === CV_STAGE_SLUG) {
    componentName = 'stage-edit';
  } else if (dmSlug === CROP_SLUG) {
    componentName = 'crop-edit';
  } else if (dmSlug === TRIAL_TYPE_SLUG) {
    componentName = 'trial-type-edit';
  }

  return {
    title: translate(dm.name_json),
    componentName: componentName,
    extraParams: extraParams,
  };
}
