import {
  GetOptions,
  ListRequestParams,
  RemoveResult,
  RequestOptions,
  listParamsToQueryString,
  makeDefaultApi,
  requestBulkRemoveEntities,
} from './request';
import { DimensionData } from './dimensions';

export interface TrialTypeData extends DimensionData {
  allow_disable_sites: boolean;
}

interface TrialTypeListRequestParams {
  archived?: boolean;
}

const trialTypesApi = makeDefaultApi<TrialTypeData>('trial_types');

export const { retrieve, save, remove } = trialTypesApi;

/** Wrapper that adds default parameters to the list request */
export function list(
  params: ListRequestParams & TrialTypeListRequestParams & { type?: string },
  options?: GetOptions,
  requestOptions?: RequestOptions
): Promise<TrialTypeData[]> {
  const defaultParams: TrialTypeListRequestParams = {
    archived: false,
  };
  return trialTypesApi.list({ ...defaultParams, ...params }, options, requestOptions);
}

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/trial_types/bulk_remove/${queryParameters}`);
}
