import * as ko from 'knockout';
import i18n from '../i18n';

import { MaybeKO, asObservable } from '../utils/ko_utils';
import * as dimensionMetasApi from '../api/dimension_metas';
import { DimensionMeta } from '../models/dimension_meta';
import { DatasetDimensionMeta, LimitToDimension } from '../models/dataset_dimension_meta';
import { DimensionsTableConfig } from '../components/dimensions_table';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { UserData } from '../api/users';
import { DatasetWizard, TrialWizard } from '../models/trial';
import { TrialState } from '../models/TrialState';
import { confirmDialog } from './confirm_dialog';
import { session } from '../session';

let template = require('raw-loader!../../templates/components/dataset_dimension_metas_edit.html').default;

export interface DatasetDimensionMetasEditConfiguration {
  user: UserData;

  addDMText: ko.Computed<string> | MaybeKO<string>;
  selectDMText: ko.Computed<string> | MaybeKO<string>;
  emptyDDMWarning: ko.Computed<string> | MaybeKO<string>;
  emptyDimensionsWarning: ko.Computed<string> | MaybeKO<string>;
  addDimensionText(ddm: DatasetDimensionMeta): string;

  trialWizard: TrialWizard;
  allowAnonymize: boolean;
  allowEditOptional: boolean;

  canEditRequired(): boolean;
  canEditDimensionMeta(ddm: DatasetDimensionMeta): boolean;
  allowEdit(): boolean;
  allowEditAny(): boolean;
  allowAdd(): boolean;
  allowRemove(ddm: DatasetDimensionMeta): boolean;
  rankingDependsOnDDM(ddm: DatasetDimensionMeta): boolean;

  // shared list of added dataset dimension metas
  datasetDimensionMetas: KnockoutObservableArray<DatasetDimensionMeta>;
  // callback for creating new dataset dimension meta when added by the user
  createDatasetDimensionMeta(): DatasetDimensionMeta;

  confirmEditEntity: () => Promise<{}>;
  confirmChangeEntities: () => Promise<{}>;

  allowViewLimitToDimensionNameAndAttributes: boolean;
  multipleSubjectsOptionEnabled?: boolean;
  allowIncludeInFactorialCombinations?: boolean;
  allowReorder: boolean;
}

class DatasetDimensionMetasEdit {
  config: KnockoutObservable<DatasetDimensionMetasEditConfiguration>;

  private selectedDDMIndex = ko.observable<number>(0);

  selectedDDM = ko.pureComputed(() => {
    let idx = this.selectedDDMIndex();
    idx = idx < this.config().datasetDimensionMetas().length ? idx : 0;

    return this.config().datasetDimensionMetas()[idx] || null; // returns null if there are no DDMs
  });

  getDimensionMetaSearchConfig(ddm: DatasetDimensionMeta): FormSelectSearchConfiguration<DimensionMeta> {
    return {
      getSummaryName: (dimensionMeta) => {
        return dimensionMeta.nameJson();
      },

      list: (params) => {
        return dimensionMetasApi
          .listExcludingDates(params)
          .then((result) => result.map((data) => new DimensionMeta(data)));
      },

      entity: ddm ? ddm.dimensionMeta : null,

      create: {
        title: i18n.t('Dimension')(),
        componentName: 'dimension-meta-edit',
        instantiate: (data) => new DimensionMeta(<dimensionMetasApi.DimensionMetaData>data),
        extraParams: { disableDate: true },
      },

      confirmEditEntity: this.config().confirmEditEntity,
      confirmChangeEntities: this.config().confirmChangeEntities,
    };
  }

  getDimensionsConfig(ddm: DatasetDimensionMeta): DimensionsTableConfig {
    if (!ddm) {
      return null;
    }

    const trialWizard = this.config().trialWizard || ddm.trialWizard;

    return {
      user: this.config().user,

      canReorder: false,
      addDimensionText: this.config().addDimensionText(ddm),
      dimensionMeta: ddm.dimensionMeta,
      dimensions: ddm.limitTo,
      crops: ddm.trial.crop,
      country: ddm.trial.country,
      region: ddm.trial.region,
      date: ddm.trial.scheduledPlantingDate,
      trialWizard: trialWizard,
      trialId: ddm.trial.id,

      allowAnonymize: this.config().allowAnonymize,
      allowEditControl: !session.isTreatmentManagementEnabledForTrial(trialWizard.trial()),
      allowEditDisable:
        APP_CONFIG.ENABLE_DIM_DISABLE && !session.isTreatmentManagementEnabledForTrial(trialWizard.trial()),
      allowEditOptional: this.config().allowEditOptional,
      allowIncludeInFactorialCombinations: this.config().allowIncludeInFactorialCombinations,
      allowEdit: () => {
        return this.config().allowEdit() || ddm.isNew();
      },
      allowEditAny: () => {
        return this.config().allowEditAny();
      },
      enableEditDimensionMeta: this.config().canEditDimensionMeta(ddm),
      enableOrderNumber: !session.isTreatmentManagementEnabledForTrial(trialWizard.trial()),
      enableDragableReorder: this.config().allowReorder,
      multipleSubjectsOptionEnabled: this.config().multipleSubjectsOptionEnabled,
      allowViewLimitToDimensionNameAndAttributes: this.config().allowViewLimitToDimensionNameAndAttributes,

      onEntityRemove: this.onEntityRemove,
      confirmChangeEntities: this.config().confirmChangeEntities,
    };
  }

  constructor(params: { config: MaybeKO<DatasetDimensionMetasEditConfiguration> }) {
    this.config = asObservable(params.config);
  }

  onEntityRemove = async (limitTo: LimitToDimension) => {
    const dimensionId = limitTo.id();

    const treatmentsToDelete = this.config()
      .trialWizard.treatments()
      .filter((treatment) => {
        return treatment.factors().some((factor) => factor.dimension().id() === dimensionId);
      });
    if (treatmentsToDelete.length > 0) {
      await confirmDialog(
        i18n.t('Warning')(),
        i18n.t(
          'This dimension element is used in treatments. By removing it, the treatments where it is used are going to be deleted. Are you sure you want to remove it?'
        )()
      );

      treatmentsToDelete.forEach((treatment) => {
        this.config().trialWizard.treatments.remove(treatment);
      });
    }
  };

  enableSelectDM(ddm: DatasetDimensionMeta): boolean {
    return !this.config().rankingDependsOnDDM(ddm) && (this.config().allowEdit() || ddm.isNew());
  }

  canAddEntity(trialWizard: TrialWizard, observationGroup: DatasetWizard): boolean {
    const groupIsNew = observationGroup.id() === null;
    return groupIsNew || [TrialState.Draft, TrialState.Preview].includes(trialWizard.trial().state());
  }

  addDDM = () => {
    let ddm = this.config().createDatasetDimensionMeta();
    this.config().datasetDimensionMetas.push(ddm);

    this._selectDDM(ddm);
  };

  selectDDM = (ddm: DatasetDimensionMeta) => {
    ddm.showErrors();
    this._selectDDM(ddm);
  };

  private _selectDDM(ddm: DatasetDimensionMeta) {
    this.selectedDDMIndex(this.config().datasetDimensionMetas.indexOf(ddm));
  }

  removeDDM = async (ddm: DatasetDimensionMeta) => {
    try {
      if (this.config().trialWizard.treatments().length > 0) {
        await confirmDialog(
          i18n.t('Removing treatment factors')(),
          i18n.t(
            'You have already added treatments. Removing factors will remove all treatments. Are you sure you want to continue?'
          )()
        );
        this.config().trialWizard.treatments([]);
      }

      this.config()
        .confirmEditEntity()
        .then(() => {
          let index = this.config().datasetDimensionMetas.indexOf(ddm);
          this.config().datasetDimensionMetas.remove(ddm);
          this.selectedDDMIndex(Math.max(0, index - 1));
        });
    } catch (e) {
      // The user rejected the dialog
      return;
    }
  };
}

ko.components.register('dataset-dimension-metas-edit', {
  viewModel: DatasetDimensionMetasEdit,
  template: template,
});
