import { FileUploadEndpoint } from '../cloud_storage_upload';
import { request } from './request';

export interface DownloadableReportLayoutData {
  id: number;
  name: string;
  description: string;
  user_file_name: string;
  file_name: string;
  modified?: string;
  modified_by_user_name?: string;
}

export type ErrorResponse = { [field: string]: string[] } 


export function retrieveDownloadableReportLayouts(): Promise<DownloadableReportLayoutData[]> {
  let url = '/api/v2/downloadable_report_layouts/';

  return request('GET', url, {}, { injectTenant: true, version: 2 });
}

export function retrieveDownloadableReportLayout(id: number): Promise<DownloadableReportLayoutData> {
  return request('GET', `/api/v2/downloadable_report_layouts/${id}/`, {}, { injectTenant: true, version: 2 });
}

export function deleteDownloadableReportLayout(id: number): Promise<void> {
  return request('DELETE', `/api/v2/downloadable_report_layouts/${id}/`, {}, { injectTenant: true, version: 2 });
}

export function updateDownloadableReportLayout(id: number, data: DownloadableReportLayoutData): Promise<DownloadableReportLayoutData | ErrorResponse> {
  return request('PUT', `/api/v2/downloadable_report_layouts/${id}/`, data, { injectTenant: true, version: 2 });
}

export function createDownloadableReportLayout(data: DownloadableReportLayoutData): Promise<DownloadableReportLayoutData | ErrorResponse> {
  return request('POST', '/api/v2/downloadable_report_layouts/', data, { injectTenant: true, version: 2 });
}

export function getDownloadableReportLayoutUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/v2/downloadable_report_layouts/upload_url/', { content_type: contentType }, { injectTenant: true, version: 2 });
}