import { request, requestWithValidation, ValidationResult } from './request';

export interface TenantsUsageData {
  tenant: string;
  labels: string[];
  values: number[];
}

export interface TenantSubscriptionData {
  id: string;
  name: string;
  subscription_plan: string;
  expiration_date: string;
  non_discounted_monthly_price: number;
  discount: number;
  annual_price: number;
  subscription_add_on_price: number;
  automatic_payment: boolean;
  subscription_comment: string;
}

export interface TenantSubscriptionFeatures {
  observation_history_enabled: boolean | null;
  observation_group_tab_enabled: boolean | null;
  map_visualization_enabled: boolean | null;
  data_warehouse_access_enabled: boolean | null;
  barcode_scan_action_enabled: boolean | null;
  api_integration_enabled: boolean | null;
  manual_trials_enabled: boolean | null;
  treatment_management_enabled: boolean | null;
  custom_reports_enabled: boolean | null;
  limit_traits_to_dimensions_enabled: boolean | null;
  mobile_derived_traits_enabled: boolean | null;
}

export function tenantsUsage(): Promise<TenantsUsageData[]> {
  return request('GET', '/api/internal/tenants_usage/');
}

export function tenantsSubscriptions(
  show: 'all' | 'active' | 'trial' | 'expired',
  features?: TenantSubscriptionFeatures
): Promise<TenantSubscriptionData[]> {
  const params = new URLSearchParams({
    show,
    ...Object.entries(features || {}).reduce((acc: Record<string, string>, [key, value]) => {
      if (value !== null && value !== false) {
        acc[key] = Number(value).toString();
      }
      return acc;
    }, {}),
  });

  return request('GET', `/api/internal/tenants_subscriptions/?${params.toString()}`);
}

export function tenantsInTrial(): Promise<TenantSubscriptionData[]> {
  return request('GET', '/api/internal/tenants_in_trial/');
}

export interface EditTenantSubscriptionData {
  id: string;
  name: string;
  is_free_trial: boolean;
  automatic_payment?: boolean; // read-only;
  is_sfsa_partner: boolean;
  observation_history_enabled: boolean;
  observation_group_tab_enabled: boolean;
  barcode_scan_action_enabled: boolean;
  manual_trials_enabled: boolean;
  map_visualization_enabled: boolean;
  data_warehouse_access_enabled: boolean;
  api_integration_enabled: boolean;
  treatment_management_enabled: boolean;
  restricted_manager_enabled: boolean;
  custom_reports_enabled: boolean;
  limit_traits_to_dimensions_enabled: boolean;
  mobile_derived_traits_enabled: boolean;
  push_notifications_enabled: boolean;
  reports_enabled: boolean;
  weather_site_limit: number;
  subscription_plan: string;
  expiration_date: string;
  actual_subscription_price: string | number;
  subscription_add_on_price: string | number;
  subscription_comment: string;
  actual_subscription_measurements: string | number;
}

export function retrieveSubscription(id: string): Promise<EditTenantSubscriptionData> {
  return request('GET', `/api/internal/${id}/tenant_subscription/`);
}

export function updateSubscription(data: EditTenantSubscriptionData): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/internal/update_tenant_subscription/', data);
}
