import * as ko from 'knockout';

import { BaseForm } from './base_form';
import * as reportApi from '../api/downloadable_report';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { DownloadableReport } from '../models/downloadable_report';

let template = require('raw-loader!../../templates/global_report_template_edit.html').default;

class DownloadableReportLayoutEditScreen extends BaseForm<reportApi.DownloadableReportLayoutData> {
  entity = ko.validatedObservable<DownloadableReport>(null);

  constructor(
    params: {
      id: number;
      initialName?: string;
      result?: Deferred<reportApi.DownloadableReportLayoutData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);
    if(params.id) {
      const promise = reportApi.retrieveDownloadableReportLayout(params.id).then((data) => {
        this.entity(new DownloadableReport(data));
      });

      this.loadedAfter(promise);
    } else {
      this.entity(new DownloadableReport());
      this.loadedAfter(Promise.resolve()).then(() => {
        this.focusFirst(componentInfo.element);
      });
    }
  } 

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      if (this.entity().id()) {
        this.executeSaveRequest(reportApi.updateDownloadableReportLayout(this.entity().id(), data)).then((validation) => {
          this.close();
        });
        return;
      }
      this.executeSaveRequest(reportApi.createDownloadableReportLayout(data)).then((validation) => {
        this.saving(false);
        if (!validation['id']) {
          this.applyModelServerErrors(this.entity(), validation as reportApi.ErrorResponse);
        } else {
          this.close()
        }
      });
    }
  };
  
}

export let reportTemplateEdit = {
  name: 'report-template-edit',
  viewModel: createWithComponent(DownloadableReportLayoutEditScreen),
  template: template,
};

ko.components.register(reportTemplateEdit.name, reportTemplateEdit);
