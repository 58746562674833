import { I18nText } from '../i18n_text';
import { CropData } from './crops';
import { DimensionMetaData } from './dimension_metas';
import { NameI18nData } from './names';
import {
  request,
  requestWithValidation,
  requestRemoveEntity,
  ValidationResult,
  RemoveResult,
  ListRequestParams,
  listRequest,
  requestRaw,
  listParamsToQueryString,
  RequestOptions,
  requestBulkRemoveEntities,
} from './request';
import { UnitData } from './units';

export interface DimensionData {
  id?: string;
  name_json: I18nText;
  anonymized_code: string;
  code: string;
  dimension_meta_id: string;
  crops: CropData[];
  archived?: boolean;
  applications?: ScheduledApplicationData[]; // detail-only
}

export interface StageByCountryData {
  observation_planting_date: string;
  stages: {
    [dimId: string]: {
      [countryId: string]: {
        name_json: I18nText;
        start_at: string;
      }[];
    };
  };
}

export interface DimensionListFilters {
  ids?: string[];
  crop_ids?: string[];
  country_id?: string;
  dataset_id?: string;
  trial_id?: string;
  measurement_meta_id?: string;
  archived?: boolean;
}

export interface ScheduledApplicationData {
  id: string;
  input_dimension_dm?: DimensionMetaData; // read-only
  input_dimension: DimensionData;
  base_date: NameI18nData;
  days_offset: string | number;
  estimated_days_after_planting: number;
  dosage: string | number;
  unit: UnitData;
}

interface DimensionListRequestParams extends ListRequestParams, DimensionListFilters {}

export function list(
  dimensionMetaId: string,
  filters: DimensionListFilters,
  params: ListRequestParams
): Promise<DimensionData[]> {
  const defaultParams: DimensionListRequestParams = {
    archived: false,
  };
  const extendedParams: DimensionListRequestParams = { ...defaultParams, ...filters, ...params };
  return listRequest('/api/dimensions/' + dimensionMetaId + '/records/', extendedParams);
}

export function listForDMS(
  dmIds: string[],
  filters: DimensionListFilters,
  params: ListRequestParams
): Promise<DimensionData[]> {
  let extendedParams: DimensionListRequestParams = { ...filters, ...params };
  return listRequest(
    '/api/dimensions/records_for_dms/?' + listParamsToQueryString({ dm_ids: dmIds }),
    extendedParams
  );
}

export interface AdvancedSearchListRequestParams extends ListRequestParams {
  [key: string]: string | string[] | number | boolean;
}

export function advancedSearch(
  dimensionMetaId: string,
  params: AdvancedSearchListRequestParams,
  requestOptions?: RequestOptions
): Promise<DimensionData[]> {
  return listRequest('/api/dimensions/' + dimensionMetaId + '/advanced_search/', params, requestOptions);
}

export function retrieve(dimensionMetaId: string, id: string): Promise<DimensionData> {
  return request('GET', '/api/dimensions/' + id + '/');
}

export function save(data: DimensionData): Promise<ValidationResult> {
  let endpoint = '/api/dimensions/';
  let method = 'POST';
  if (data.id) {
    endpoint += data.id + '/';
    method = 'PUT';
  } else {
    endpoint += data.dimension_meta_id + '/new/';
  }

  return requestWithValidation(method, endpoint, data);
}

export function remove(dimensionMetaId: string, id: string): Promise<RemoveResult> {
  return requestRemoveEntity('/api/dimensions/' + id + '/');
}

export type ImportDimensionsDuplicatesAction =
  | 'ignore_duplicates'
  | 'update_duplicates'
  | 'ignore_duplicates_code'
  | 'update_duplicates_code'
  | 'insert';

export function downloadImportTemplate(dmIdOrSlug: string): Promise<Blob> {
  return requestRaw('GET', '/api/dimensions/' + dmIdOrSlug + '/import_template/');
}

export function getStagesByCountry(trialId: string, ids: string[]): Promise<StageByCountryData> {
  return request('POST', '/api/dimensions/stages_by_country/', {
    trial_id: trialId,
    ids,
  });
}

export function archive(id: string): Promise<{}> {
  return request('POST', '/api/dimensions/' + id + '/archive/');
}

export function unarchive(dimensionId: string, dimensionElementId: string): Promise<{}> {
  return request(
    'PATCH',
    `/api/v2/dimensions/${dimensionId}/elements/${dimensionElementId}/`,
    {
      archived: false,
    },
    {
      injectTenant: true,
      version: 2,
    }
  );
}

export function bulkRemove(dimensionMetaId: string, params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(
    `/api/dimensions/${dimensionMetaId}/records/bulk_remove/${queryParameters}`
  );
}
