import {
  ListRequestParams,
  requestRaw,
  makeDefaultApi,
  requestWithValidation,
  listParamsToQueryString,
  request,
  RemoveResult,
  requestBulkRemoveEntities,
  GetOptions,
  RequestOptions,
} from './request';
import { DimensionData } from './dimensions';
import { CountryData } from './countries';
import { CropData } from './crops';
import { BreederData } from './organizations';
import { NameI18nData } from './names';

export interface PartnerCropVarietyData extends DimensionData {
  crop_id: CropData;
}

export interface CropVarietyData extends DimensionData {
  crop_id: CropData;
  variety_type: NameI18nData;
  origin: BreederData;
  origin_type?: NameI18nData; // read-only
  origin_2: BreederData;
  origin_2_type?: NameI18nData; // read-only
  stages?: CropVarietyStageData[]; // returned only by retrieve
  crop_editable?: boolean; // returned only by retrieve
  parent_1: NameI18nData; // returned only by retrieve
  parent_2: NameI18nData; // returned only by retrieve
}

export interface CropVarietyListRequestParams extends ListRequestParams {
  ids?: string[];
  dataset_id?: string;
  crop_ids?: string[];
  sort_by: string;
  archived?: boolean;
}

export interface CropVarietyStageData {
  id: string;
  country: CountryData;
  stage: DimensionData;
  start_at: string;
  external: boolean;
  comment: string;
}

export interface CropVarietyStageItemData {
  crop_variety_id: string;
  stage_id: string;
  start_at: string;
  external: boolean;
  comment: string;
}

export interface CropVarietyMultiStageData {
  country_id: string;
  items: CropVarietyStageItemData[];
}

const cropVarietiesApi = makeDefaultApi<CropVarietyData, CropVarietyListRequestParams>('crop_varieties');

export const { retrieve, save, remove } = cropVarietiesApi;

/** Wrapper that adds default parameters to the list request */
export function list(
  params: ListRequestParams & CropVarietyListRequestParams & { type?: string },
  options?: GetOptions,
  requestOptions?: RequestOptions
): Promise<CropVarietyData[]> {
  const defaultParams: CropVarietyListRequestParams = {
    sort_by: 'anonymized_code',
    archived: false,
  };
  return cropVarietiesApi.list({ ...defaultParams, ...params }, options, requestOptions);
}

export function addCVStage(id: string, data: CropVarietyStageData) {
  return requestWithValidation('POST', '/api/crop_varieties/' + id + '/add_stage/', data);
}

export function updateStages(data: CropVarietyMultiStageData): Promise<void> {
  return request('POST', '/api/crop_varieties/update_stages/', data);
}

export function downloadExport(filters: { crop_ids?: string[] }): Promise<Blob> {
  return requestRaw('GET', '/api/crop_varieties/export/?' + listParamsToQueryString(filters));
}

export function downloadCVStagesImportTemplate(): Promise<Blob> {
  return requestRaw('GET', '/api/crop_varieties/import_cv_stages_template/');
}

export function bulkRemove(params: {}): Promise<RemoveResult> {
  const queryParameters = params ? '?' + listParamsToQueryString(params) : '';
  return requestBulkRemoveEntities(`/api/crop_varieties/bulk_remove/${queryParameters}`);
}
