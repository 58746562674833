import * as ko from 'knockout';
import page from 'page';
import firebase from 'firebase/app';
import * as tenantsApi from './api/base_tenants';
import { STORAGE_KEYS } from './utils';
import { Trial } from './models/trial';

class Session {
  private user: firebase.User;

  loggedIn = ko.observable(false);
  // Be careful when using tenant() directly, it can be null.
  // Because `strictNullChecks` is not enabled, the Typescript compiler will not warn
  // you if you access a property of tenant() that is not defined.
  tenant = ko.observable<tenantsApi.TenantData | null>(null);
  tenants = ko.observableArray<tenantsApi.TenantData>();

  authorized = ko.pureComputed(() => {
    return this.loggedIn() && this.tenant();
  });

  isAdmin = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && tenant.role === 'admin';
  });

  isReadOnlyAdmin = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && tenant.role === 'read_only_admin';
  });

  needsEmailVerification = ko.computed(() => {
    return this.loggedIn() && this.user.tenantId === null && !this.user.emailVerified;
  });

  isManager = ko.pureComputed(() => this.tenant()?.role === 'manager' ?? false);

  isEditor = ko.pureComputed(() => this.tenant()?.role === 'editor' ?? false);

  isRestrictedManager = ko.pureComputed(() => this.tenant()?.role === 'restricted_manager' ?? false);

  isAtLeastReadOnlyAdmin = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && (tenant.role === 'admin' || tenant.role === 'read_only_admin');
  });

  isAtLeastTemplateEditor = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && (tenant.role === 'admin' || tenant.role == 'template_editor');
  });

  isAtLeastStaff = ko.pureComputed(() => {
    return !this.isPartner() && !this.isCompany();
  });

  isPartner = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && tenant.role === 'partner';
  });

  isCompany = ko.pureComputed(() => {
    let tenant = this.tenant();
    return tenant !== null && tenant.role === 'company';
  });

  isProducer = ko.pureComputed(() => {
    return this.isCompany() && this.tenant() && this.tenant().producers.length > 0;
  });

  isBuyer = ko.pureComputed(() => {
    return this.isCompany() && this.tenant() && this.tenant().buyers.length > 0;
  });

  isFieldtrials = ko.pureComputed(() => session.tenant() && session.tenant().user_type === 'fieldtrials');
  isS2bim = ko.pureComputed(() => session.tenant() && session.tenant().user_type === 's2bim');

  init(firebaseConfig: Object) {
    firebase.initializeApp(firebaseConfig);
    let isRoutingInitialized = false;

    firebase.auth().onAuthStateChanged((user) => {
      this.user = user;
      if (!!this.user) {
        this.loggedIn(true);

        if (this.needsEmailVerification()) {
          this.sendEmailVerification();
          this.pollEmailVerificationStatus();
          page();
          return;
        }
        this.getToken(true)
          .then((token) => {
            document.cookie = `firebaseToken=${token}; path=/; SameSite=Strict; secure`;
            this.reloadPageIfDocsPage();
            return tenantsApi.authorizations(token, false);
          })
          .then((res) => {
            this.tenants(res.tenants);
            this.updateTenant();
            if (!isRoutingInitialized) {
              isRoutingInitialized = true;
              page();
            }
          });
      } else {
        document.cookie =
          'firebaseToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict; secure';
        this.loggedIn(false);
        this.tenants([]);
        this.updateTenant();
        if (!isRoutingInitialized) {
          isRoutingInitialized = true;
          page();
        }
      }
    });
  }

  reloadPageIfDocsPage() {
    // sometimes server can redirect user to main page so token can be refreshed
    // so if refreshing was successful we need to redirect user back to docs page
    if (
      this.user &&
      (window.location.pathname.startsWith('/docs') || window.location.pathname.startsWith('/devdocs'))
    ) {
      // redirect to the same page with new token
      // redirect is needed so server can return new index.html for docs or devdocs
      window.location.reload();
    }
  }

  getEmail(): string {
    return this.user?.email;
  }

  isSfsaPartner(): boolean {
    return this.tenant().is_sfsa_partner;
  }

  getName(): string {
    return this.user?.displayName;
  }

  /**
   * Retrieves the Firebase Auth ID token for the current user.
   * @param {boolean} forceRefresh - Optional. Forces token refresh if true, which is useful
   *                                 when email verification status is updated.
   * @returns {Promise<string>} A promise that resolves with the ID token string.
   *                            If no user is authenticated, returns undefined.
   */
  getToken(forceRefresh: boolean = false): Promise<string> {
    if (!this.user) {
      return undefined;
    }
    return this.user.getIdToken(forceRefresh);
  }


  getEmailVerificationStorageKey() {
    // Have user ID in the key so if the user tries with another account, we still sent the verification email.
    return `email-verification-sent-for-${this.user.uid}-at`;
  }

  sendEmailVerification() {
    if (!this.user) {
      return;
    }

    // Firebase can block verification requests if we send them too often. Limit to once every 15 minutes.
    const storageKey = this.getEmailVerificationStorageKey();
    const emailVerificationSentAt = localStorage.getItem(storageKey);
    if (emailVerificationSentAt !== null) {
      const timeSinceLastSentEmail = Date.now() - Number(emailVerificationSentAt);
      if (timeSinceLastSentEmail < 15 * 60 * 1000) {
        return;
      }
    }

    this.user.sendEmailVerification();
    localStorage.setItem(storageKey, Date.now().toString());
  }

  /**
   * Polls the email verification status of the current user asynchronously.
   * Reloads the page once the user's email is verified.
   * @returns {void}
   */
  pollEmailVerificationStatus(): void {
    const checkStatus = async () => {
      await this.user.reload();
      if (this.user.emailVerified) {
        const storageKey = this.getEmailVerificationStorageKey();
        localStorage.removeItem(storageKey);
        window.location.reload();
      } else {
        setTimeout(checkStatus, 3000);
      }
    };

    setTimeout(checkStatus, 4000);
  }

  async logout(): Promise<void> {
    await firebase.auth().signOut();

    // Clear the local state once the user has logged out
    localStorage.removeItem(STORAGE_KEYS.OVERVIEW_CLICK_ACTION);
    document.cookie =
      'firebaseToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict; secure';
  }

  getLocationTenantSlug(pathname: string): string {
    let match = pathname.match(/^\/t\/([^\/]+)/);
    return match ? match[1] : null;
  }

  updateTenant(pathname?: string) {
    let slug = this.getLocationTenantSlug(pathname === undefined ? location.pathname : pathname);
    var found = false;

    if (slug) {
      for (let tenant of this.tenants()) {
        if (tenant.slug === slug) {
          found = true;
          if (tenant !== this.tenant()) {
            this.tenant(tenant);
          }
          return;
        }
      }
    }

    if (!found) {
      this.tenant(null);
    }
  }

  toTenantPath(path: string, tenant?: tenantsApi.TenantData): string {
    tenant = tenant || this.tenant();
    if (tenant) {
      return '/t/' + tenant.slug + path;
    } else {
      return path;
    }
  }

  hasCountryRole(countryId: string, role: 'head' | 'editor' | 'viewer'): boolean {
    let tenant = this.tenant();
    return tenant && tenant.country_roles[countryId] === role;
  }

  isTreatmentManagementEnabledForTrial(trial: Trial): boolean {
    return trial.wasCreatedUsingTreatmentsFeature();
  }

  is(role: 'head' | 'editor' | 'viewer'): boolean {
    let tenant = this.tenant();
    if (!tenant) {
      return false;
    }

    for (let key in tenant.country_roles) {
      if (tenant.country_roles[key] === role) {
        return true;
      }
    }

    return false;
  }

  isAtLeastHeadFor(country: { id: string }): boolean {
    if (this.isAdmin()) {
      return true;
    }

    return this.hasCountryRole(country.id, 'head');
  }

  isAtLeastEditorFor(country: { id: string }): boolean {
    if (this.isAdmin() || (this.isFieldtrials() && !this.isReadOnlyAdmin())) {
      return true;
    }

    return this.hasCountryRole(country.id, 'head') || this.hasCountryRole(country.id, 'editor');
  }

  isAtLeastHead(): boolean {
    return this.isAdmin() || this.is('head');
  }

  isAtLeastEditor(): boolean {
    let role = this.tenant() && this.tenant().role;
    return (
      this.isAdmin() ||
      this.is('head') ||
      this.is('editor') ||
      role === 'template_editor' ||
      role === 'editor' ||
      role === 'manager'
    );
  }
}

export let session = new Session();
(<any>window)['session'] = session; // used in index.html
