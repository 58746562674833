import * as ko from 'knockout';

import { BaseForm } from '../base_form';
import { createWithComponent } from '../../utils/ko_utils';
import { EditTenantSubscriptionData, retrieveSubscription, updateSubscription } from '../../api/internal';
import { parseDate, serializeDate } from '../../api/serialization';
import { emptyToNull, readDecimal } from '../../utils';
import { Deferred } from '../../utils/deferred';

const template = require('raw-loader!../../../templates/internal/tenant_subscription_edit.html').default;

export const ALL_PLANS: { id: string; name: string }[] = [
  { id: '', name: 'Free Trial' },
  { id: 'small', name: 'Small' },
  { id: 'medium', name: 'Medium' },
  { id: 'large', name: 'Large' },
  { id: 'xlarge', name: 'X-Large' },
  { id: 'enterprise', name: 'Enterprise' },
  { id: 'small-yearly', name: 'Small (Yearly)' },
  { id: 'medium-yearly', name: 'Medium (Yearly)' },
  { id: 'large-yearly', name: 'Large (Yearly)' },
  { id: 'xlarge-yearly', name: 'X-Large (Yearly)' },
  { id: 'enterprise-yearly', name: 'Enterprise (Yearly)' },
  { id: 'small-weather', name: 'Small (Weather)' },
  { id: 'medium-weather', name: 'Medium (Weather)' },
  { id: 'large-weather', name: 'Large (Weather)' },
  { id: 'xlarge-weather', name: 'X-Large (Weather)' },
  { id: 'enterprise-weather', name: 'Enterprise (Weather)' },
  { id: 'small-yearly-weather', name: 'Small (Yearly + Weather)' },
  { id: 'medium-yearly-weather', name: 'Medium (Yearly + Weather)' },
  { id: 'large-yearly-weather', name: 'Large (Yearly + Weather)' },
  { id: 'xlarge-yearly-weather', name: 'X-Large (Yearly + Weather)' },
  { id: 'enterprise-yearly-weather', name: 'Enterprise (Yearly + Weather)' },
  { id: 'small-advanced_weather', name: 'Small (Advanced Weather)' },
  { id: 'medium-advanced_weather', name: 'Medium (Advanced Weather)' },
  { id: 'large-advanced_weather', name: 'Large (Advanced Weather)' },
  { id: 'xlarge-advanced_weather', name: 'X-Large (Advanced Weather)' },
  { id: 'enterprise-advanced_weather', name: 'Enterprise (Advanced Weather)' },
  {
    id: 'small-yearly-advanced_weather',
    name: 'Small (Yearly + Advanced Weather)',
  },
  {
    id: 'medium-yearly-advanced_weather',
    name: 'Medium (Yearly + Advanced Weather)',
  },
  {
    id: 'large-yearly-advanced_weather',
    name: 'Large (Yearly + Advanced Weather)',
  },
  {
    id: 'xlarge-yearly-advanced_weather',
    name: 'X-Large (Yearly + Advanced Weather)',
  },
  {
    id: 'enterprise-yearly-advanced_weather',
    name: 'Enterprise (Yearly + Advanced Weather)',
  },
];

class TenantSubscription {
  private id: string;

  planOptions = ALL_PLANS;
  booleanOptions = [
    { id: 'No', value: 'false' },
    { id: 'Yes', value: 'true' },
  ];

  allowEditSubscriptionPlan = ko.observable(false);
  initialData = ko.observable<EditTenantSubscriptionData>(null);
  name = ko.observable('');
  subscriptionPlan = ko.observable('').extend({ serverError: true });
  sfsaPartner = ko.observable('');
  freeTrial = ko.observable('');
  observationHistoryEnabled = ko.observable('');
  observationGroupEnabled = ko.observable('');
  barcodeScanActionEnabled = ko.observable('');
  manualTrialsEnabled = ko.observable('');
  mapVisualizationEnabled = ko.observable('');
  dataWarehouseAccessEnabled = ko.observable('');
  apiIntegrationEnabled = ko.observable('');
  treatmentManagementEnabled = ko.observable('');
  restrictedManagerEnabled = ko.observable('');
  pushNotificationsEnabled = ko.observable('');
  reportsEnabled = ko.observable('');
  customReportsEnabled = ko.observable('');
  limitTraitsToDimensionsEnabled = ko.observable('');
  mobileDerivedTraitsEnabled = ko.observable('');
  weatherSiteLimit = ko.observable().extend({ number: true, min: 0 });
  expirationDate = ko.observable<Date>().extend({ required: true });
  actualSubscriptionPrice = ko.observable('').extend({ number: true });
  subscriptionAddOnPrice = ko.observable('').extend({ number: true });
  subscriptionComment = ko.observable('');
  actualSubscriptionMeasurements = ko.observable('').extend({ digit: true, serverError: true });

  hasSubscriptionPlanChanged = ko.pureComputed(() => {
    const initialData = this.initialData();
    return initialData && initialData.subscription_plan !== this.subscriptionPlan();
  });

  constructor(data: EditTenantSubscriptionData) {
    this.initialData(data);

    this.id = data.id;
    this.name(data.name);
    this.freeTrial(data.is_free_trial ? 'true' : 'false');
    this.subscriptionPlan(data.subscription_plan);
    this.sfsaPartner(data.is_sfsa_partner ? 'true' : 'false');
    this.observationHistoryEnabled(data.observation_history_enabled ? 'true' : 'false');
    this.customReportsEnabled(data.custom_reports_enabled ? 'true' : 'false');
    this.observationGroupEnabled(data.observation_group_tab_enabled ? 'true' : 'false');
    this.barcodeScanActionEnabled(data.barcode_scan_action_enabled ? 'true' : 'false');
    this.manualTrialsEnabled(data.manual_trials_enabled ? 'true' : 'false');
    this.mapVisualizationEnabled(data.map_visualization_enabled ? 'true' : 'false');
    this.dataWarehouseAccessEnabled(data.data_warehouse_access_enabled ? 'true' : 'false');
    this.apiIntegrationEnabled(data.api_integration_enabled ? 'true' : 'false');
    this.treatmentManagementEnabled(data.treatment_management_enabled ? 'true' : 'false');
    this.restrictedManagerEnabled(data.restricted_manager_enabled ? 'true' : 'false');
    this.pushNotificationsEnabled(data.push_notifications_enabled ? 'true' : 'false');
    this.reportsEnabled(data.reports_enabled ? 'true' : 'false');
    this.limitTraitsToDimensionsEnabled(data.limit_traits_to_dimensions_enabled ? 'true' : 'false');
    this.mobileDerivedTraitsEnabled(data.mobile_derived_traits_enabled ? 'true' : 'false');
    this.weatherSiteLimit(data.weather_site_limit);
    this.expirationDate(parseDate(data.expiration_date));
    this.actualSubscriptionPrice(readDecimal(data.actual_subscription_price));
    this.subscriptionAddOnPrice(readDecimal(data.subscription_add_on_price));
    this.subscriptionComment(data.subscription_comment);
    this.actualSubscriptionMeasurements(data.actual_subscription_measurements?.toString() ?? '');

    this.allowEditSubscriptionPlan(!data.automatic_payment);
  }

  toData(): EditTenantSubscriptionData {
    return {
      id: this.id,
      name: this.name(),
      is_sfsa_partner: this.sfsaPartner() === 'true',
      is_free_trial: this.freeTrial() === 'true',
      observation_history_enabled: this.observationHistoryEnabled() === 'true',
      observation_group_tab_enabled: this.observationGroupEnabled() === 'true',
      barcode_scan_action_enabled: this.barcodeScanActionEnabled() === 'true',
      manual_trials_enabled: this.manualTrialsEnabled() === 'true',
      map_visualization_enabled: this.mapVisualizationEnabled() === 'true',
      data_warehouse_access_enabled: this.dataWarehouseAccessEnabled() === 'true',
      api_integration_enabled: this.apiIntegrationEnabled() === 'true',
      treatment_management_enabled: this.treatmentManagementEnabled() === 'true',
      restricted_manager_enabled: this.restrictedManagerEnabled() === 'true',
      push_notifications_enabled: this.pushNotificationsEnabled() === 'true',
      reports_enabled: this.reportsEnabled() === 'true',
      custom_reports_enabled: this.customReportsEnabled() === 'true',
      limit_traits_to_dimensions_enabled: this.limitTraitsToDimensionsEnabled() === 'true',
      mobile_derived_traits_enabled: this.mobileDerivedTraitsEnabled() === 'true',
      weather_site_limit: parseInt(this.weatherSiteLimit(), 10),
      subscription_plan: emptyToNull(this.subscriptionPlan()),
      expiration_date: serializeDate(this.expirationDate()),
      actual_subscription_price: emptyToNull(this.actualSubscriptionPrice()),
      subscription_add_on_price: emptyToNull(this.subscriptionAddOnPrice()),
      subscription_comment: this.subscriptionComment(),
      actual_subscription_measurements: emptyToNull(this.actualSubscriptionMeasurements()),
    };
  }
}

class TenantSubscriptionEditScreen extends BaseForm<EditTenantSubscriptionData> {
  entity = ko.validatedObservable<TenantSubscription>(null);

  constructor(
    params: { id: string; result?: Deferred<EditTenantSubscriptionData> },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    const promise = retrieveSubscription(params.id).then((data) => {
      this.entity(new TenantSubscription(data));
    });
    this.loadedAfter(promise);
  }

  save = async () => {
    if (this.validateLocal(this.entity)) {
      const data = this.entity().toData();
      const validation = await this.executeSaveRequest(updateSubscription(data));
      this.onRemoteValidation(data, this.entity(), validation);
    }
  };
}

export const tenantSubscriptionEdit = {
  name: 'tenant-subscription-edit',
  viewModel: createWithComponent(TenantSubscriptionEditScreen),
  template,
};

ko.components.register(tenantSubscriptionEdit.name, tenantSubscriptionEdit);
