import { request } from '../request';
import { TestSubjectDimension, TrialVisits } from './interfaces';
import { TrialLocationsSummary } from '../datasets';
import * as queryString from 'query-string';

export const trialVisits = (id: number, siteId?: string): Promise<TrialVisits> => {
  let url = `/api/v2/trials/${id}/visits/`;
  if (siteId) {
    url += `?site_id=${siteId}`;
  }
  return request('GET', url, {}, { injectTenant: true, version: 2 });
};


export const trialFacts = (
  id: string,
  params: { sitesIds?: string[]; startDate?: string; endDate?: string; usersIds?: string[] }
): Promise<TrialLocationsSummary> => {
  let url = `/api/v2/trials/${id}/map_fact_list/`;
  if (params) {
    url += `?${queryString.stringify(params)}`;
  }
  return request('GET', url, {}, { injectTenant: true, version: 2 });
};

export const visitsForFacts = (
  id: string,
  params: { siteIds?: string[]; startDate?: string; endDate?: string; usersIds?: string[] }
): Promise<any> => {
  let url = `/api/v2/trials/${id}/visits_for_map_facts/`;
  if (params) {
    url += `?${queryString.stringify(params)}`;
  }
  return request('GET', url, {}, { injectTenant: true, version: 2 });
};

export const trialStaffUsers = (trialID: string, siteID: string) => {
  let url = `/api/v2/trials/${trialID}/users_with_facts/?siteID=${siteID}`;
  return request('GET', url, {}, { injectTenant: true, version: 2 });
};

export const getTrialTestSubjectDimensionMetas = (trialId: string): Promise<TestSubjectDimension[]> => {
  let url = `/api/v2/trials/${trialId}/treatment-types/`;
  return request('GET', url, {}, { injectTenant: true, version: 2 });
};
